import { useCallback } from "react";

import { useQueryClient } from "react-query";

import { useMsal } from "@azure/msal-react";

import { UserDetails } from "interfaces";
import { apiUrls as urls } from "config";
import { makeFetchRequest, useGetFetcher } from "api";

import { useGetLogout } from "../api";
import { useUserDetails } from "../auth-state";

import { loginRequest } from "./auth-config";

export const useLoginAzureAd = () => {
  const { instance, inProgress, accounts } = useMsal();

  return useCallback(async () => {
    if (inProgress === "none" && accounts.length === 0) {
      await instance.loginRedirect(loginRequest);
    }
  }, [instance, inProgress]);
};

export const useTokenCookieExchange = () => {
  const url = urls?.me;
  const logout = useGetLogout();
  const { instance, accounts, inProgress } = useMsal();
  const [, updateUserDetails] = useUserDetails();

  return useCallback(async () => {
    if (!url) {
      throw Error("Url for <me> not passed");
    }

    if (inProgress === "none" || inProgress === "handleRedirect") {
      const token = await instance.acquireTokenSilent({
        ...loginRequest,
        // @ts-ignore
        account: accounts[0],
      });

      const idToken = token.idToken;
      try {
        const userDetails = await makeFetchRequest<UserDetails>({
          url,
          method: "POST",
          data: { idToken },
        });
        updateUserDetails(userDetails);
      } catch (e) {
        await instance.logout();
        await logout();
        return;
      }
    }
  }, [url, updateUserDetails, instance, accounts, inProgress]);
};

export const useLogout = () => {
  const { instance } = useMsal();
  const url = urls?.logout;
  const client = useQueryClient();

  const fetcher = useGetFetcher<void>();

  return useCallback(async () => {
    if (!url) {
      throw Error("Logout URL not in context!!!");
    }
    client.clear();
    try {
      await fetcher({ url, method: "POST" });
    } catch (e) {}
    try {
      await instance.logout();
    } catch (e) {}
  }, [url, client, fetcher]);
};

export default useLoginAzureAd;
