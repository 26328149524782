import { useCallback, useEffect } from "react";

import { atom, PrimitiveAtom } from "jotai";
import {
  atomWithStorage,
  atomFamily,
  useUpdateAtom,
  useAtomValue,
  useAtomCallback,
} from "jotai/utils";

import { Api } from "interfaces";
import { fetchTaskStatus } from "api/compute/task-status";
import { fetchTasks } from "./api";

interface Task extends Api.TaskStatus, Api.Task {}

const taskList = atomWithStorage<string[]>("tasks/list", []);

const task = atomFamily(
  (taskId: string) => atom<Task>(null) as PrimitiveAtom<Task>
);

const taskSeen = atomFamily((taskId: string) =>
  atomWithStorage<boolean>(`task/seen/${taskId}`, null)
);

export const useGetTaskSeen = (taskId: string) =>
  useAtomValue(taskSeen(taskId));

export const useSetTaskSeen = (taskId: string) =>
  useUpdateAtom(taskSeen(taskId));

const taskFetchInProgress = atomFamily((taskId: string) =>
  atomWithStorage<boolean>(`task/fetch-in-progress/${taskId}`, null)
);

export const useUpdateTaskListFromServer = () =>
  useAtomCallback(
    useCallback(async (get, set) => {
      const listing = await fetchTasks();
      set(
        taskList,
        listing.map((l) => l.job_id)
      );
      listing.map((l) => set(task(l.job_id), l));
      listing
        .filter((t) => t.status === "IN PROGRESS")
        .forEach(async (t) => {
          const inProgress = get(taskFetchInProgress(t.job_id));
          if (!inProgress) {
            set(taskSeen(t.job_id), false);
            set(taskFetchInProgress(t.job_id), true);
            const resolved = await fetchTaskStatus(t.job_id);
            set(task(t.job_id), { ...t, ...resolved });
            set(taskFetchInProgress(t.job_id), false);
            set(taskSeen(t.job_id), false);
          }
        });
    }, [])
  );

const taskListSelector = atom<Task[]>((get) => {
  const listing = get(taskList);
  return listing.map((id) => get(task(id))).filter((t) => t);
});

export const useGetTaskList = () => useAtomValue(taskListSelector);

export const unSeenTaskListSelector = atom<string[]>((get) => {
  const listing = get(taskList);
  return listing.filter((id) => !get(taskSeen(id)));
});

export const useGetUnseenTasks = () => useAtomValue(unSeenTaskListSelector);

export const useUpdateTasks = () => {
  const update = useUpdateTaskListFromServer();
  useEffect(() => {
    update();
  }, []);
};
