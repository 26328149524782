import { useState } from "react";
import Link from "next/link";

import { UserDetails } from "interfaces";

import HeaderLink from "./Header-Link";

interface Props {
  paths: { [key: string]: string };
  loggedIn: boolean;
  logout: () => any;
  allowSignup: boolean;
  userDetails?: UserDetails;
}

const SmallScreenHeader = ({
  paths,
  loggedIn,
  userDetails,
  logout,
  allowSignup,
}: Props) => {
  const [hamburgerIsOpen, openHamburger] = useState(false);

  return (
    <div>
      <div className="lg:hidden flex justify-end">
        <nav className="block" onClick={() => openHamburger(!hamburgerIsOpen)}>
          <button className="navbar-burger flex justify-end items-center py-2 px-3 text-brand-600 rounded border border-brand-600">
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </nav>
      </div>
      <div
        className={`navbar-menu w-full h-0 ${
          hamburgerIsOpen ? "" : "hidden"
        } bg-white text-right pb-2 divide-y divide-gray-200 align-top`}
      >
        <div>
          <HeaderLink to={paths.uploadData} label="Upload Data" />
          <HeaderLink to={paths.dataFiles} label="Explore" />
          <HeaderLink to={paths.createData} label="Transform" />
          {/* <HeaderLink to={paths.dataMacro} label="Your Macros" /> */}
        </div>
        <div>
          <HeaderLink to={paths.recipes} label="Start a New Project" />
          <HeaderLink to={paths.projects} label="See Your Existing Projects" />
          {/* <HeaderLink to={paths.modelMacros} label="Your Model Macros" /> */}
        </div>
        {loggedIn && userDetails ? (
          <>
            <HeaderLink to={paths.profile} label={userDetails.email} />
            <p
              onClick={logout}
              className="block lg:inline-block hover:bg-gray-300 w-full"
            >
              <span
                className={
                  "mt-2 lg:mt-0 font-medium text-gray-600 lg:px-5 lg:pt-3 lg:flex lg:items-center transition duration-150 ease-in-out"
                }
              >
                Log Out
              </span>
            </p>
          </>
        ) : (
          <>
            <HeaderLink to={paths.login} label="Sign in" />
            {allowSignup && (
              <div className="btn-sm text-gray-200 bg-brand-600 hover:bg-brand-900 mt-2 rounded">
                <Link href={paths.signup}>
                  <a>
                    <p className="font-medium transition duration-150 ease-in-out">
                      Try for Free
                    </p>
                  </a>
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SmallScreenHeader;
