import Alert from "ui-components/Alert";

import { Api } from "interfaces";

type RespError =
  | string
  | Error
  | Api.ApiError
  | Api.ComputeStatus["error_message"]
  | Api.TaskStatus["result"];

const errorIsApiError = (error: unknown): error is Api.ApiError => {
  if (typeof error !== "object") {
    return false;
  }

  return (
    !!(error as Api.ApiError)?.detail ||
    !!(error as Api.ApiError)?.non_field_errors
  );
};

const errorIsTaskStatus = (
  error: RespError
): error is Api.TaskStatus["result"] => {
  if (
    typeof error === "string" ||
    error instanceof Error ||
    Array.isArray(error) ||
    errorIsApiError(error)
  ) {
    return false;
  }

  return error?.error !== undefined;
};

const ErrorComponent = ({ error }: { error: RespError }) => {
  console.log(error);
  if (error instanceof Error) {
    error = error.message;
  }
  if (!error) {
    return <Alert>Error</Alert>;
  }
  if (typeof error === "string") {
    return <Alert>{error}</Alert>;
  }

  if (Array.isArray(error)) {
    return (
      <Alert>
        <ul>
          {error.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </Alert>
    );
  }

  if (errorIsApiError(error)) {
    return (
      <Alert>
        <ul>
          {error.non_field_errors &&
            (typeof error.non_field_errors === "string" ? (
              <li>{error.non_field_errors}</li>
            ) : (
              <>
                {error.non_field_errors.map((e) => (
                  <li key={e}>{e}</li>
                ))}
              </>
            ))}
          {error.detail &&
            (typeof error.detail === "string" ? (
              <li>{error.detail}</li>
            ) : Array.isArray(error.detail) ? (
              error.detail.map((err) => <li key={err}>{err}</li>)
            ) : (
              <>
                {Object.entries(error.detail).map(([k, e]) => (
                  <li key={k}>
                    {k}: {Array.isArray(e) ? e.join(", ") : JSON.stringify(e)}
                  </li>
                ))}
              </>
            ))}
        </ul>
      </Alert>
    );
  }

  if (errorIsTaskStatus(error)) {
    return (
      <Alert>
        {error?.error_message || error?.message || "Error: Unable to Compute"}
      </Alert>
    );
  }

  return (
    <Alert>
      {Object.entries(error).map(([k, e]) => (
        <li key={k}>
          {k}: {typeof e === "string" ? e : e.join(", ")}
        </li>
      ))}
    </Alert>
  );
};

export default ErrorComponent;
