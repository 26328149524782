import { useState } from "react";
import clsx from "clsx";

import Transition from "ui-components/Transition";

interface Props {
  title: React.ReactNode;
  dropdownAtEnd?: boolean;
  size?: "regular" | "large" | "xl";
  onOpen?: () => unknown;
}

const Dropdown: React.FC<Props> = ({
  children,
  title,
  dropdownAtEnd = true,
  size = "regular",
  onOpen,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => {
        setDropdownOpen(true);
        onOpen?.();
      }}
      onMouseLeave={() => setDropdownOpen(false)}
      onFocus={() => {
        setDropdownOpen(true);
        onOpen?.();
      }}
      onBlur={() => setDropdownOpen(false)}
      onClick={() => setDropdownOpen(false)}
    >
      <a
        className="font-medium text-gray-100 hover:text-gray-100 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
        href="#0"
        // aria-haspopup="true"
        aria-expanded={dropdownOpen}
        onClick={(e) => e.preventDefault()}
      >
        {title}
        <svg
          className={clsx(
            `w-3 h-3 fill-current text-gray-300 ml-1 flex-shrink-0`,
            !dropdownAtEnd && "hidden"
          )}
          viewBox="0 0 12 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10.28 4.305L5.989 8.598 1.695 4.305A1 1 0 00.28 5.72l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z" />
        </svg>
      </a>
      {children && (
        <Transition
          show={dropdownOpen}
          tag="ul"
          className={clsx(
            "absolute top-full bg-white py-2 ml-4 rounded shadow-lg",
            "right-0",
            size === "regular" && "w-56",
            size === "large" && "w-96",
            size === "xl" && "w-144"
          )}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          {children}
        </Transition>
      )}
    </div>
  );
};

export default Dropdown;
