import { useEffect } from "react";

import { Project } from "interfaces";

import { useGetTaskList } from "modules/notifications/store";

import { useResetProjectQuery } from "./project-api";

export const useGetOpenTasksForProject = (project: Project) => {
  const workspaces = project.workspaces.map((w) => w.workspace);
  return useGetTaskList()
    .filter((t) => workspaces.includes(t?.meta_data?.workspace))
    .filter((t) => t.status === "IN PROGRESS");
};

export const useOpenTasksUpdate = (project: Project) => {
  const openTasks = useGetOpenTasksForProject(project);

  const resetQuery = useResetProjectQuery(project.id);

  useEffect(() => {
    resetQuery();
  }, [openTasks.length, resetQuery]);

  return openTasks;
};
