import { useEffect, useCallback } from "react";
import * as localForage from "localforage";

import { useQuery } from "react-query";
import { destroyCookie } from "nookies";

import { UserDetails } from "interfaces";
import config, { apiUrls as urls } from "config";
import { useGetFetcher, makeFetchRequest } from "api";

import {
  useUserDetails,
  useResetUserDetails,
  useLogoutStatus,
  useGetLogoutStatus,
} from "./auth-state";

import { useLogout as useAzureLogout } from "./azure-ad/api";
import { useLogout as useFirebaseLogout } from "./firebase/api";
import { useLogout as useDaishoLogout } from "./daisho-jwt/api";

const identityProvider = config.IDENTITY_PROVIDER;

export const fetchProfile = async (
  fetcher: typeof makeFetchRequest = makeFetchRequest,
  authToken?: string
) => {
  const url = urls?.me || "me";
  return (await fetcher({ url, method: "GET", authToken })) as UserDetails;
};

export const useUpdateProfile = () => {
  const url = urls?.me || "me";

  const fetcher = useGetFetcher<UserDetails>();
  const [userDetails, updateUserDetails] = useUserDetails();
  const resetUserDetails = useResetUserDetails();

  const logoutInProgress = useGetLogoutStatus();

  const { data, status } = useQuery<UserDetails>(url, () => fetcher({ url }), {
    retry: false,
    enabled: !logoutInProgress,
  });

  useEffect(() => {
    if (!logoutInProgress) {
      if (status === "success") {
        data && updateUserDetails(data);
        localStorage.setItem("current-user", data.email);
        localForage.setItem("current-user", data.email);
      } else if (status === "error") {
        resetUserDetails();
        localStorage.removeItem("current-user");
        localForage.removeItem("current-user");
      }
    }
  }, [updateUserDetails, resetUserDetails, data, status, logoutInProgress]);

  return { status, userDetails };
};

export const useGetLogout = () => {
  const [logoutInProgress, setLogoutInProgress] = useLogoutStatus();
  const daishoLogout = useDaishoLogout();
  const firebaseLogout = useFirebaseLogout();
  const azureLogout = useAzureLogout();

  const reset = useResetUserDetails();

  return useCallback(async () => {
    if (!logoutInProgress) {
      setLogoutInProgress(true);
      try {
        switch (identityProvider) {
          case "azure-ad":
            await azureLogout();
          case "firebase":
            await firebaseLogout();
          default:
            await daishoLogout();
        }
      } catch (e) {}

      localStorage.removeItem("current-user");
      localForage.removeItem("current-user");
      reset();
      destroyCookie(null, "daisho-jwt");
      setLogoutInProgress(false);
    }
  }, [
    logoutInProgress,
    setLogoutInProgress,
    daishoLogout,
    firebaseLogout,
    azureLogout,
    reset,
  ]);
};
