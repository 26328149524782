import Link from "next/link";

import { Api } from "interfaces";

import { useFetchProject } from "modules/projects/api";

interface Task extends Api.TaskStatus, Api.Task {}

const AddWorkspaceMessage = ({ task }: { task: Task }) => {
  const { status, meta_data } = task;

  const { data: project } = useFetchProject(meta_data.project);

  const projectLink = (
    <Link href={`/projects/${meta_data.project}`}>
      <a className="underline cursor-pointer">
        Project {project?.name || meta_data.project}
      </a>
    </Link>
  );

  switch (status) {
    case "SUCCESS":
      if (task.result?.error) {
        return (
          <p>
            Failed to create features for model-building in {projectLink}
            {task.result?.error_message && (
              <span>: {task.result?.error_message?.toString()}</span>
            )}
          </p>
        );
      }
      return (
        <p>Successfully created features for model-building in {projectLink}</p>
      );
    case "FAILURE":
      const errorMessage = task.result?.error_message;
      return (
        <p>
          Failed to create features for model-building in {projectLink}
          {errorMessage && <span>: {errorMessage.toString()}</span>}
        </p>
      );
    case "IN PROGRESS":
      return <p>Creating features for model-building in {projectLink}</p>;
    default:
      return null;
  }
};

export default AddWorkspaceMessage;
