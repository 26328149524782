import Link from "next/link";

import { Api } from "interfaces";

import { useFetchProject } from "modules/projects/api";

interface Task extends Api.TaskStatus, Api.Task {}

const BuildModelMessage = ({ task }: { task: Task }) => {
  const { workspace, algorithm, annotations } = task.meta_data || {};
  const { projectId } = annotations || {};
  const { data: project } = useFetchProject(projectId);

  if (projectId) {
    const projectLink = (
      <Link
        href={
          project
            ? `/${project?.flow}/${projectId}/models/summary`
            : annotations.url || `/projects/${projectId}`
        }
      >
        <a className="underline cursor-pointer">
          Project {project?.name || projectId}
        </a>
      </Link>
    );
    const errorMessage = task.result?.error_message;
    if (task.result?.error) {
      return (
        <p>
          Failed to make model using{" "}
          {algorithm && <span>using {algorithm} </span>}in {projectLink}
          {errorMessage && <span>: {errorMessage.toString()}</span>}
        </p>
      );
    }

    switch (task.status) {
      case "SUCCESS":
        return (
          <p>
            Successfully built models{" "}
            {algorithm && <span>using {algorithm} </span>}in {projectLink}
          </p>
        );
      case "FAILURE":
        return (
          <p>
            Failed to make model using{" "}
            {algorithm && <span>using {algorithm} </span>}in {projectLink}
            {errorMessage && <span>: {errorMessage.toString()}</span>}
          </p>
        );
      case "IN PROGRESS":
        return (
          <p>
            Building model using {algorithm && <span>using {algorithm} </span>}
            in {projectLink}
          </p>
        );
      default:
        return null;
    }
  }

  const workspaceLink = (
    <Link
      href={
        annotations?.link ||
        (projectId ? `/projects/${projectId}` : `/workspaces/${workspace}`)
      }
    >
      {annotations?.project ? (
        <a className="underline cursor-pointer">
          Project {annotations.project}
        </a>
      ) : (
        <a className="underline cursor-pointer">Workspace {workspace}</a>
      )}
    </Link>
  );

  if (task.status === "FAILURE") {
    const errorMessage = task.result?.error_message;
    return (
      <p>
        Unable to create model using {algorithm} in {workspaceLink}
        {errorMessage && <span>: {errorMessage}</span>}
      </p>
    );
  }

  if (task.status === "SUCCESS") {
    const models = (task.result?.data as { models: string[] })?.models || [];

    return (
      <p>
        Successfully generated model{models.length >= 2 ? "s" : ""} in{" "}
        {workspaceLink}.
      </p>
    );
  }

  return (
    <p>
      Creating model using {algorithm} in {workspaceLink}
    </p>
  );
};

export default BuildModelMessage;
