import dynamic from "next/dynamic";

import config from "config";

const FirebaseWrapper = dynamic(() => import("./firebase/Wrapper"), {
  ssr: false,
});
const AzureADWrapper = dynamic(() => import("./azure-ad/Wrapper"), {
  ssr: false,
});

const AuthWrapper = ({
  children,
}: {
  children: React.ReactChild | React.ReactChildren;
}) => {
  switch (config.IDENTITY_PROVIDER) {
    case "firebase":
      return (
        <>
          {children}
          {config.IDENTITY_PROVIDER === "firebase" && <FirebaseWrapper />}
        </>
      );
    case "azure-ad":
      return <AzureADWrapper>{children}</AzureADWrapper>;
    default:
      return <>{children}</>;
  }
};

export default AuthWrapper;
