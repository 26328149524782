import { useCallback } from "react";

import { Project, Api, TransformData, Workspaces } from "interfaces";
import { apiUrls as urls } from "config";
import { useCompute, useGetFetcher } from "api";

export interface CreateProps {
  flow: string;
  data_files: { [key: string]: string | string[] };
  meta_data?: { [key: string]: any };
}

type RetType =
  | { success: true; project: Project }
  | { success: false; error: Api.ApiError };

export const useCreateProject = () => {
  const fetcher = useGetFetcher<Project>();

  const createProject = useCallback(
    async (spec: CreateProps): Promise<RetType> => {
      const url = urls.projects;
      try {
        const resp = await fetcher({
          url,
          method: "POST",
          data: spec,
        });
        return { success: true, project: resp };
      } catch (e) {
        return { success: false, error: e as Api.ApiError };
      }
    },
    [fetcher]
  );

  return createProject;
};

interface CleanupSpec {
  source_data: [string];
  create_type: "CLEANUP";
  create_spec: Omit<TransformData.CleanupSpec, "name" | "source_data">;
}

interface TimeSeriesSpec {
  source_data: [string];
  create_type: "TIME-SERIES";
  create_spec: Omit<
    TransformData.DataConvertToTimeSeriesSpec,
    "name" | "source_data"
  >;
}

interface AggregateSpec {
  source_data: [string];
  create_type: "AGGREGATE";
  create_spec: Omit<
    TransformData.DataGroupedAggregatesSpec,
    "name" | "source_data"
  >;
}

interface AugmentSpec {
  source_data: [string];
  create_type: "AUGMENT";
  create_spec: Omit<TransformData.DataAugmentSpec, "name" | "source_data">;
}

interface FilterSpec {
  source_data: [string];
  create_type: "FILTER";
  create_spec: Omit<TransformData.FilterSpec, "name" | "source_data">;
}

interface JoinSpec {
  source_data: string[];
  create_type: "JOIN";
  create_spec: Omit<TransformData.JoinSpec, "name" | "source_data">;
}

interface StackSpec {
  source_data: string[];
  create_type: "STACK";
  create_spec: Omit<TransformData.StackSpec, "name" | "source_data">;
}

type DataSpec = { key: string; output_file_name: string } & (
  | CleanupSpec
  | TimeSeriesSpec
  | AggregateSpec
  | AugmentSpec
  | FilterSpec
  | JoinSpec
  | StackSpec
);

type Param = number | string | Date | string[] | number[] | Date[];

interface WorkspaceSpec extends Workspaces.CreateSpec {
  key: string;
  meta_data?: { [key: string]: Param };
  annotations?: { [key: string]: Param };
}

interface Spec {
  "project-spec": CreateProps;
  "data-spec"?: DataSpec | DataSpec[];
  "workspace-spec"?: WorkspaceSpec | WorkspaceSpec[];
}

export const useCreateProjectWithDataAndWorkspace = () => {
  const compute = useCompute<Spec, Project>(
    urls.createProjectsWithWorkspace,
    true
  );
  const createProject = useCallback(
    async (spec: Spec) => {
      try {
        const resp = await compute({ data: spec });
        return { success: true, project: resp };
      } catch (e) {
        return { success: false, error: e as Api.ApiError };
      }
    },
    [compute]
  );

  return createProject;
};
