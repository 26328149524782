import { useCallback } from "react";
import { useQueryClient, QueryClient } from "react-query";

import { apiUrls as urls } from "config";

import { Api, Project } from "interfaces";
import { useGetFetcher } from "api";

const updateClient = (
  client: QueryClient,
  projectId: string,
  metadataToAdd?: { [key: string]: any },
  metadataToRemove?: string[]
) => {
  const data = client.getQueryData<Project>([urls.projects, projectId]);
  const newMetaData = Object.fromEntries(
    Object.entries({ ...data.meta_data, ...(metadataToAdd || {}) }).filter(
      ([k]) => !(metadataToRemove || []).includes(k)
    )
  );
  const updatedData = { ...data, meta_data: newMetaData };
  client.setQueryData<Project>([urls.projects, projectId], updatedData);
  client.setQueryData<{ pages: Project[][] }>(urls.projects, (entities) => {
    if (!entities) {
      return { pages: [] };
    }
    const { pages } = entities;
    return {
      ...entities,
      pages: (pages || []).map((l) =>
        l.map((d) => (d.id !== projectId ? d : updatedData))
      ),
    };
  });
};

export const useUpdateProjectMetaData = (projectId?: string) => {
  const fetcher = useGetFetcher<Project>();
  const client = useQueryClient();

  return useCallback(
    async (meta_data: { [key: string]: any }, mId?: string) => {
      const id = mId || projectId;
      if (!id) {
        throw Error("Project ID not passed!!!");
      }
      if (Object.keys(meta_data).length === 0) {
        return;
      }
      const url = `${urls.projects}/${projectId}/update-meta-data`;
      const resp = await fetcher({
        url,
        method: "POST",
        data: { meta_data },
      });
      if ((resp as Api.ComputeStatus).error) {
        throw (resp as Api.ComputeStatus).error_message;
      }
      updateClient(client, id, meta_data, []);
      return resp;
    },
    [projectId, fetcher]
  );
};

export const useRemoveProjectMetaData = (projectId?: string) => {
  const fetcher = useGetFetcher<Project>();
  const client = useQueryClient();

  return useCallback(
    async (keys_to_be_removed: string[], mId?: string) => {
      const id = mId || projectId;
      if (!id) {
        throw Error("Model ID not passed!!!");
      }
      if ((keys_to_be_removed || []).length === 0) {
        return;
      }
      const url = `${urls.projects}/${projectId}/remove-annotations`;
      const resp = await fetcher({
        url,
        method: "POST",
        data: { keys_to_be_removed },
      });
      if ((resp as Api.ComputeStatus).error) {
        throw (resp as Api.ComputeStatus).error_message;
      }
      updateClient(client, id, {}, keys_to_be_removed);
      return resp;
    },
    [projectId, fetcher]
  );
};
