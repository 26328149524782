import { parseCookies } from "nookies";
import axios, { AxiosRequestConfig, AxiosPromise } from "axios";

export type RequestType =
  | "get"
  | "GET"
  | "post"
  | "POST"
  | "put"
  | "PUT"
  | "patch"
  | "PATCH"
  | "delete"
  | "DELETE";

const createFormDataWithFiles = (
  files: File | File[] | { [key: string]: File | File[] },
  params?: { [key: string]: any }
) => {
  let data = new FormData();
  if (Array.isArray(files)) {
    files.forEach((f) => {
      data.append("files", f, f.name);
    });
  } else if (files instanceof File) {
    data.append("file", files);
  } else {
    Object.entries(files).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        values.forEach((v) => {
          data.append(key, v, v.name);
        });
      } else {
        data.append(key, values, values.name);
      }
    });
  }
  if (params) {
    Object.keys(params).forEach((key) =>
      data.append(key, JSON.stringify(params[key]))
    );
  }

  return data;
};

const buildRequest = async (
  url: string,
  method: RequestType = "GET",
  data?: { [key: string]: any } | string | number,
  timeout = 1000 * 60,
  files?: File | File[] | { [key: string]: File | File[] },
  onUploadProgress?: (progress: number) => any,
  authToken?: string
) => {
  let config: AxiosRequestConfig = {
    timeout,
    withCredentials: true,
    headers: { accept: "application/json" },
  };

  if (!files) {
    config.headers["content-type"] = "application/json";
  }

  const cookies = parseCookies();
  const token = cookies?.["daisho-jwt"] || authToken;
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }

  if (onUploadProgress) {
    config.onUploadProgress = (progressEvent) => {
      const completed = progressEvent.loaded / progressEvent.total;
      onUploadProgress(completed);
    };
  }

  let request: AxiosPromise;

  switch (method) {
    case "post":
    case "POST":
      if (files) {
        data = createFormDataWithFiles(files, data as { [key: string]: any });
      }
      request = axios.post(url, data, config);
      break;
    case "put":
    case "patch":
    case "PUT":
    case "PATCH":
      request = axios.put(url, data, config);
      break;
    case "delete":
    case "DELETE":
      request = axios.delete(url, { ...config, data });
      break;
    default:
      request = axios.get(url, { ...config, params: data });
  }

  return request;
};

export default buildRequest;
