import { Recipe } from "interfaces";
import { apiUrls as urls } from "config";

import { useFetchEntity, useFetchPaginatedData } from "api";

export const useFetchRecipeListing = () =>
  useFetchPaginatedData<Recipe.Recipe>(urls.recipes);

export const useFetchRecipe = (recipe: string) =>
  useFetchEntity<Recipe.Recipe>(urls.recipes, recipe);
