import Link from "next/link";

import APP_CONFIG from "config";

const Footer = () => {
  return (
    <footer className="py-2 border-t">
      <p className="text-center text-base">
        <Link href={"/terms"}>
          <a>Terms and Conditions</a>
        </Link>{" "}
        |{" "}
        <Link href={"/privacy"}>
          <a>Privacy Policy</a>
        </Link>
      </p>

      <p className="text-center text-base">
        Made with <span style={{ color: "#e25555" }}>&#9829;</span> in India.
      </p>

      <p className="text-center text-base">
        {"Copyright © "}
        {APP_CONFIG.SITE_NAME} {new Date().getFullYear()}
        {"."}
      </p>
    </footer>
  );
};

export default Footer;
