import { useCallback } from "react";
import JSON5 from "json5";
import { Api } from "interfaces";

import { makeFetchRequest, useGetFetcher } from "../fetch";

import { fetchTaskStatus } from "./task-status";

type InsightResponse<T = unknown> = {
  data?: T;
  compute_status: Api.ComputeStatus;
} & { request_id: string };

const throwError = (error?: any) => {
  const err = new Error(error || "Unable to compute");
  throw err;
};

export async function compute<T extends {}>(
  url: string,
  fetcher?: typeof makeFetchRequest,
  data?: T,
  files?: File | File[] | { [key: string]: File | File[] },
  onUploadProgress?: (progress: number) => any,
  fetchDataAfterTaskCompletion?: false
): Promise<Api.TaskStatus>;
export async function compute<T extends {}, R = any>(
  url: string,
  fetcher?: typeof makeFetchRequest,
  data?: T,
  files?: File | File[] | { [key: string]: File | File[] },
  onUploadProgress?: (progress: number) => any,
  fetchDataAfterTaskCompletion?: true
): Promise<R>;
export async function compute<T extends {}, R = any>(
  url: string,
  fetcher?: typeof makeFetchRequest,
  data?: T,
  files?: File | File[] | { [key: string]: File | File[] },
  onUploadProgress?: (progress: number) => any,
  fetchDataAfterTaskCompletion: boolean = true
): Promise<R | Api.TaskStatus> {
  fetcher = fetcher || makeFetchRequest;
  let resp = await fetcher<InsightResponse<R>>({
    method: "POST",
    url,
    data,
    files,
    onUploadProgress,
  });

  if (typeof resp === "string") {
    try {
      resp = JSON5.parse(resp);
    } catch (e) {
      console.log("LINE 40", resp);
    }
  }

  if (!resp.request_id) {
    const { data, compute_status } = resp;
    // Received results.
    (compute_status.error || !data) && throwError(compute_status.error_message);
    return data as R;
  }

  // Task was queued. Now keep checking.
  const taskStatus = await fetchTaskStatus<R>(resp.request_id);
  if (taskStatus.status === "FAILURE") {
    throwError(taskStatus.result?.error_message);
  }

  if (!fetchDataAfterTaskCompletion) {
    return taskStatus;
  }

  if (taskStatus.data) {
    return taskStatus.data;
  }

  return await compute(url, fetcher, data, files, onUploadProgress, true);
}

interface ComputeArgs<T extends {}> {
  data?: T;
  files?: File | File[] | { [key: string]: File | File[] };
  computeUrl?: string;
  onUploadProgress?: (progress: number) => any;
}

export function useCompute<T extends {}>(
  url?: string,
  fetchDataAfterTaskCompletion?: false
): (args?: ComputeArgs<T>) => Promise<Api.TaskStatus>;
export function useCompute<T extends {}>(
  url?: string,
  fetchDataAfterTaskCompletion?: false
): (args?: ComputeArgs<T>) => Promise<Api.TaskStatus>;
export function useCompute<T extends {}, R = any>(
  url?: string,
  fetchDataAfterTaskCompletion?: true
): (args?: ComputeArgs<T>) => Promise<R>;
export function useCompute<T extends {}, R = any>(
  url?: string,
  fetchDataAfterTaskCompletion: boolean = true
): (args?: ComputeArgs<T>) => Promise<R | Api.TaskStatus> {
  const fetcher = useGetFetcher();

  return useCallback(
    (args: ComputeArgs<T> = {}) => {
      const { data, computeUrl, files, onUploadProgress } = args || {};
      const urlToCall = computeUrl || url;
      if (!urlToCall) {
        throw "URL not passed. Neither is Compute URL";
      }
      if (fetchDataAfterTaskCompletion) {
        return compute<T, R>(
          urlToCall,
          fetcher,
          data,
          files,
          onUploadProgress,
          true
        );
      } else {
        return compute<T>(
          urlToCall,
          fetcher,
          data,
          files,
          onUploadProgress,
          false
        );
      }
    },
    [fetcher, url, fetchDataAfterTaskCompletion]
  );
}
