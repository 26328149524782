import { useReducer } from "react";

interface UploadStatus {
  completed?: boolean;
  error?: boolean;
  progress?: number;
  errorMessage?: string | { [key: string]: string };
  status?: string;
}

type ResetAction = { type: "reset" };
type InitAction = { type: "init"; payload: { files: File | File[] } };
type ProgressUpdateAction = {
  type: "update-progress";
  payload: { file: string; progress: number };
};
type StatusUpdateAction = {
  type: "update-status";
  payload: { file: string; completed: boolean; error?: string };
};

const dataUploadReducer = (
  state: { [name: string]: UploadStatus },
  action: ResetAction | InitAction | ProgressUpdateAction | StatusUpdateAction
) => {
  switch (action.type) {
    case "init":
      const { files } = action.payload;
      if (Array.isArray(files)) {
        return {
          ...state,
          ...Object.fromEntries(
            files.map((f) => [
              f.name,
              { completed: false, error: false, progress: 0 },
            ])
          ),
        };
      } else {
        return {
          ...state,
          [files.name]: { completed: false, error: false, progress: 0 },
        };
      }
    case "update-progress":
      const { file, progress } = action.payload;
      if (state[file]?.progress === progress) {
        return state;
      }
      return { ...state, [file]: { ...(state[file] || {}), progress } };
    case "update-status":
      const { file: f, completed, error } = action.payload;
      if (
        state[f]?.completed === completed &&
        state[f]?.errorMessage === error
      ) {
        return state;
      }
      if (completed && !error) {
        return {
          ...state,
          [f]: { completed: true, error: false, progress: 100 },
        };
      } else if (error) {
        return {
          ...state,
          [f]: {
            completed: false,
            error: true,
            errorMessage: error,
            progress: 100,
          },
        };
      } else {
        return { ...state, [f]: { ...(state[f] || {}), completed } };
      }
    case "reset":
      return {};
    default:
      return state;
  }
};

export const useGetDataUploadReducer = () => useReducer(dataUploadReducer, {});
