import {
  useQuery,
  useQueries,
  useInfiniteQuery,
  QueryObserverResult,
  QueryStatus,
  FetchNextPageOptions,
  FetchPreviousPageOptions,
} from "react-query";

import { Api } from "interfaces";
import { useGetFetcher } from "../fetch";

type ApiError = Api.ApiError;

interface Entity {
  id: string;
}

interface KeyedEntity {
  key: string;
}

export function useFetchEntity<T extends Entity | KeyedEntity>(
  baseUrl: string,
  entityId: string | undefined,
  staleTime: number = 60 * 60 * 1000,
  cacheTime: number = 60 * 60 * 1000
) {
  const makeFetchRequest = useGetFetcher<T>();
  return useQuery<T, ApiError>(
    [baseUrl, entityId],
    () => makeFetchRequest({ url: `${baseUrl}/${entityId}` }),
    {
      staleTime,
      cacheTime,
      enabled: !!entityId,
    }
  );
}

export function useFetchMultipleEntities<T extends Entity | KeyedEntity>(
  baseUrl: string,
  entityIds: string[],
  staleTime: number = 60 * 60 * 1000,
  cacheTime: number = 60 * 60 * 1000
) {
  const makeFetchRequest = useGetFetcher<T>();
  return useQueries(
    entityIds.map((id) => ({
      queryKey: [baseUrl, id],
      queryFn: () => makeFetchRequest({ url: `${baseUrl}/${id}` }),
      staleTime,
      cacheTime,
      enabled: id !== null && id !== undefined,
    }))
  ) as QueryObserverResult<T, ApiError>[];
}

interface ListingResult<T = any> {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
}

export function useFetchPaginatedData<T = any>(
  baseUrl: string
): [
  T[][],
  QueryStatus,
  boolean | undefined,
  (options?: FetchNextPageOptions) => any,
  (options?: FetchPreviousPageOptions) => any
] {
  const makeFetchRequest = useGetFetcher<ListingResult<T>>();
  const {
    status,
    data,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
  } = useInfiniteQuery<T[]>(
    baseUrl,
    async ({ pageParam = 0 }) => {
      const requiredUrl = pageParam
        ? `${baseUrl}${baseUrl.includes("?") ? "&" : "?"}offset=${pageParam}`
        : baseUrl;

      const data = await makeFetchRequest({
        url: requiredUrl,
      });
      return data.results;
    },
    {
      getNextPageParam: (lastPage, pages) =>
        (lastPage || []).length >= 10
          ? pages.map((p) => p.length).reduce((a, b) => a + b, 0)
          : undefined,
    }
  );

  return [
    data?.pages || [],
    status,
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
  ];
}
