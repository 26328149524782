import { useCallback } from "react";
import { useQuery, useQueries, QueryObserverResult } from "react-query";

import { apiUrls as urls } from "config";

import { Api, Workspaces } from "interfaces";
import { useGetFetcher, useFetchPaginatedData } from "api";
import {
  useShareEntity,
  useRevokeEntityAccessPermissions,
  useDeleteEntity,
  useFetchEntity,
  useFetchMultipleEntities,
} from "api";

export const useFetchWorkspaceListing = () =>
  useFetchPaginatedData<Workspaces.WorkSpace>(urls.workspaces);

export const useFetchWorkspace = (workspaceId: string | undefined) =>
  useFetchEntity<Workspaces.WorkSpace>(urls.workspaces, workspaceId);

export const useFetchMultipleWorkspaces = (workspaceIds: string[]) =>
  useFetchMultipleEntities<Workspaces.WorkSpace>(urls.workspaces, workspaceIds);

export const useGetShareWorkspace = (workspaceId?: string) => {
  const shareEntity = useShareEntity();
  return useCallback(
    (
      permissions: { can_view?: string[]; can_use?: string[] },
      wsId?: string
    ) => {
      const ws = wsId || workspaceId;
      if (!ws) {
        throw Error("MUST pass Workspace ID");
      }
      return shareEntity({
        baseUrl: urls.workspaces,
        entityId: ws,
        permissions: permissions,
      });
    },
    [urls, shareEntity, workspaceId]
  );
};

export const useGetRevokeWorkspacePermissions = (workspaceId?: string) => {
  const revokePermissions = useRevokeEntityAccessPermissions();

  return useCallback(
    (
      permissions: { can_view?: string[]; can_use?: string[] },
      wsId?: string
    ) => {
      const ws = wsId || workspaceId;
      if (!ws) {
        throw Error("MUST pass Workspace ID");
      }
      return revokePermissions({
        baseUrl: urls.workspaces,
        entityId: ws,
        permissions: permissions,
      });
    },
    [urls, revokePermissions, workspaceId]
  );
};

export const useDeleteWorkspace = (workspaceId?: string) => {
  const deleteData = useDeleteEntity();
  return useCallback(
    (wsId?: string) => {
      const ws = wsId || workspaceId;
      if (!ws) {
        throw Error("MUST pass Workspace ID");
      }
      return deleteData({
        baseUrl: urls.workspaces,
        entityId: ws,
      });
    },
    [urls, workspaceId, deleteData]
  );
};

export const useGetDisplayParams = (workspaceId: string | undefined) => {
  const url = `${urls.workspaces}/${workspaceId}/display-params`;
  const fetcher = useGetFetcher<Workspaces.DisplayParams>();

  return useQuery<Workspaces.DisplayParams, Api.ApiError>(
    workspaceId ? url : "",
    () => fetcher({ url }),
    {
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!workspaceId,
    }
  );
};

export const useGetDisplayParamsForMultipleWS = (workspaceIds: string[]) => {
  const fetcher = useGetFetcher<Workspaces.DisplayParams>();

  return useQueries(
    workspaceIds.map((id) => ({
      queryKey: `${urls.workspaces}/${id}/display-params`,
      queryFn: () =>
        fetcher({ url: `${urls.workspaces}/${id}/display-params` }),
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!id,
    }))
  ) as QueryObserverResult<Workspaces.DisplayParams, Api.ApiError>[];
};

export const useGetIterateParams = (workspaceId: string | undefined) => {
  const url = `${urls.workspaces}/${workspaceId}/iterate-params`;
  const fetcher = useGetFetcher<Workspaces.IterateParams>();

  return useQuery<Workspaces.IterateParams, Api.ApiError>(
    workspaceId ? url : "",
    () => fetcher({ url }),
    {
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!workspaceId,
    }
  );
};

export const useGetIterateParamsForMultipleWS = (workspaceIds: string[]) => {
  const fetcher = useGetFetcher<Workspaces.IterateParams>();

  return useQueries(
    workspaceIds.map((id) => ({
      queryKey: `${urls.workspaces}/${id}/iterate-params`,
      queryFn: () =>
        fetcher({ url: `${urls.workspaces}/${id}/iterate-params` }),
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!id,
    }))
  ) as QueryObserverResult<Workspaces.IterateParams, Api.ApiError>[];
};

export const useCreateIterativeModel = () => {
  const fetcher = useGetFetcher<{ request_id: string }>();
  return useCallback(
    async (workspaceId: string, iterateSpec: {}) => {
      const url = `${urls.workspaces}/${workspaceId}/iterate`;
      const resp = await fetcher({
        url,
        method: "POST",
        data: iterateSpec,
      });
      return resp;
    },
    [fetcher]
  );
};
