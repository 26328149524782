import { useQuery, useQueryClient } from "react-query";

import { FlatDistribution, DifferenceStats, DataFile } from "interfaces";
import { apiUrls as urls } from "config";
import { useCompute, useGetFetcher } from "api";
import { useCallback } from "react";

interface ModelUpdates {
  id: string;
  created_time: string;
  data_only: boolean;
  annotations: { [key: string]: any };
}

export function useFetchUpdates(modelId: string) {
  const fetcher = useGetFetcher();
  const url = `${urls.predictive_models}/${modelId}/${urls.update_listing}`;
  return useQuery<ModelUpdates[], string>(url, () => fetcher({ url }));
}

export function useFetchUpdateDetails(modelId: string, updateId: string) {
  const fetcher = useGetFetcher();
  const url = `${urls.predictive_models}/${modelId}/${urls.ml_ops_get_update}?update_id=${updateId}`;
  return useQuery<ModelUpdates, string>(url, () => fetcher({ url }));
}

export function useFetchDataDrift(modelId: string, updateId: string) {
  const url = `${urls.predictive_models}/${modelId}/${urls.ml_ops_data_drift}`;
  const data = { update_id: updateId };
  const compute = useCompute<typeof data, { [key: string]: DifferenceStats }>(
    url,
    true
  );

  return useQuery<{ [key: string]: DifferenceStats }, Error>(
    [url, data],
    () => compute({ data }),
    {
      staleTime: 24 * 60 * 60 * 1000,
      cacheTime: 24 * 60 * 60 * 1000,
      retry: false,
      enabled: !!modelId && !!updateId,
    }
  );
}

interface ColumnDetails {
  stats: DataFile.ColumnStatsInterface;
  distribution: FlatDistribution;
}

export function useFetchModelUpdateColumnDetails(
  modelId: string,
  updateId: string,
  column: string
) {
  const url = `${urls.predictive_models}/${modelId}/${urls.ml_ops_column_details}`;
  const data = { update_id: updateId, column: column };
  const compute = useCompute<typeof data, ColumnDetails>(url, true);

  return useQuery<ColumnDetails, Error>([url, data], () => compute({ data }), {
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    retry: false,
    enabled: !!modelId && !!updateId && !!column,
  });
}

export const useDeleteUpdate = (modelId: string) => {
  const client = useQueryClient();
  const url = `${urls.predictive_models}/${modelId}/${urls.ml_ops_delete_update}`;

  const compute = useCompute<{ update_id: string }>(url, false);
  const submit = useCallback(
    async (updateId: string) => {
      const data = { update_id: updateId };

      await compute({ data });

      const updates = client.getQueryData<ModelUpdates[]>(url);
      client.setQueryData<ModelUpdates[]>(
        url,
        updates.filter((u) => u.id !== updateId)
      );
    },
    [url, compute]
  );
  return submit;
};
