import { useState, useEffect, useCallback } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { useUpdateProfile, useGetLogout } from "auth/api";
import { checkForExpiry } from "auth/utils";
import { useGetLoginStatus, useGetUserDetails } from "auth/auth-state";

import APP_CONFIG from "config";
import paths from "./paths";

import BigScreenNav from "./Big-Screen-Nav";
import SmallScreenNav from "./Small-Screen-Nav";

const useLogout = () => {
  const router = useRouter();
  const logout = useGetLogout();

  return useCallback(() => {
    logout();
    router.push("/");
  }, [logout, router]);
};

const Flash = () => {
  const userDetails = useGetUserDetails();
  if (!userDetails) {
    return null;
  }

  const { active, expiryDate, reason } = checkForExpiry(userDetails);

  if (active) {
    return null;
  }

  if (reason === "license") {
    return (
      <div className="text-gray-900 bg-yellow-500">
        <p className="text-center p-1">
          Your license {expiryDate ? "" : "has "}expired
          {expiryDate && <span> on {expiryDate}</span>}. Contact your
          administrator @ {APP_CONFIG.ADMIN_CONTACT} for more details, and next
          steps.
        </p>
      </div>
    );
  }

  return (
    <div className="text-gray-900 bg-yellow-500">
      <p className="text-center p-1">
        Your subscription {expiryDate ? "" : "has "}expired
        {expiryDate && <span> on {expiryDate}</span>}.{" "}
        <Link href="/profile/billing">
          <a className="underline cursor-pointer">Click here</a>
        </Link>{" "}
        to renew your subscription.
      </p>
    </div>
  );
};

const Header = () => {
  useUpdateProfile();
  const userDetails = useGetUserDetails();

  const loggedIn = useGetLoginStatus();
  const onLogout = useLogout();

  const allowSignUp = APP_CONFIG.SIGN_IN_ALLOWED;

  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  // const showTour = useTourGuideStore((store) => store.showCurrent);

  return (
    <>
      <header
        className={`w-full pt-2 z-30 md:bg-opacity-90 transition duration-300 ease-in-out bg-indigo-700 ${
          !top && "bg-white blur shadow-lg"
        }`}
      >
        <div className="max-w-screen-2xl mx-auto px-5 sm:px-6">
          <div className="flex lg:flex-no-wrap items-center justify-between">
            {/* Site branding */}
            <div className="flex-shrink-0 mr-4">
              {/* Logo */}
              <Link href="/">
                <a className="block" aria-label="Go to Homepage">
                  <img
                    src={APP_CONFIG.LOGO_FILE_PATH}
                    alt={APP_CONFIG.SITE_NAME}
                    className="h-16 md:h-20 object-contain"
                    width={200}
                    height={80}
                  />
                </a>
              </Link>
            </div>

            {/* Site navigation */}
            {/* Big Screen Navigation. Hidden for sm and below. */}
            <BigScreenNav
              paths={paths}
              loggedIn={loggedIn}
              logout={onLogout}
              userDetails={userDetails}
              allowSignup={allowSignUp}
            />

            {/* Small Screen Navigation: Hamburger */}
            <SmallScreenNav
              paths={paths}
              loggedIn={loggedIn}
              logout={onLogout}
              userDetails={userDetails}
              allowSignup={allowSignUp}
            />
          </div>
        </div>
        {/* <ExpiryFlash /> */}
      </header>
      <Flash />
    </>
  );
};

export default Header;
