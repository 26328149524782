import { useCallback } from "react";
import { useQueryClient } from "react-query";

import firebase from "firebase/app";
import "firebase/auth";

import { useGetFetcher, makeFetchRequest } from "api";
import { apiUrls as urls } from "config";
import { UserDetails } from "interfaces";

import {
  useUserDetails,
  useLoginProgress,
  useResetUserDetails,
} from "../auth-state";

export const useTokenCookieExchange = () => {
  const url = urls?.me;
  const [, setLoginProgress] = useLoginProgress();
  const logout = useLogout();
  const [, updateUserDetails] = useUserDetails();

  return useCallback(
    async (user: firebase.User) => {
      if (!url) {
        throw Error("Url for <me> not passed");
      }
      const firebaseUserDetails = {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        photoURL: user.photoURL,
        uid: user.uid,
        phoneNumber: user.phoneNumber,
        providerData: user.providerData,
      };
      setLoginProgress(true);

      // Force refresh: Every call ensures the id-token is refreshed, and the user's still logged-in.
      const idToken = await user.getIdToken(true);
      try {
        // const resp = await fetch(url, {
        //   method: "POST",
        //   mode: "cors",
        //   credentials: "include",
        //   body: JSON.stringify({
        //     idToken,
        //     userDetails: firebaseUserDetails,
        //   }),
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //   },
        // });
        // const userDetails = (await resp.json()) as UserDetails;
        // updateUserDetails(userDetails);
        const userDetails = await makeFetchRequest<UserDetails>({
          url,
          method: "POST",
          data: { idToken, userDetails: firebaseUserDetails },
        });
        updateUserDetails(userDetails);
        setLoginProgress(false);
      } catch (e) {
        setLoginProgress(false);
        await logout();
        return;
      }
    },
    [url, logout, updateUserDetails, setLoginProgress]
  );
};

export const useLogout = () => {
  const url = urls?.logout;
  const client = useQueryClient();

  const fetcher = useGetFetcher<void>();
  const resetUserDetails = useResetUserDetails();

  return useCallback(async () => {
    if (!url) {
      throw Error("Logout URL not in context!!!");
    }
    client.clear();
    resetUserDetails();
    await firebase.auth().signOut();
    await fetcher({ url, method: "POST" });
  }, [url, client, fetcher]);
};
