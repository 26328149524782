import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate, HydrateProps } from "react-query/hydration";
import { ApiContext } from "./api-context";

interface Props {
  dehydratedState?: HydrateProps["state"];
  /** Callback on Auth Failure: Passed automatically to ALL API calls inside react-query. */
  onAuthFailure?: () => any;
  /** Children  */
  children: React.ReactChild | React.ReactChildren;
  queryClient?: QueryClient;
}

const defaultFunc = () => {};

const APIContainer = ({
  onAuthFailure,
  dehydratedState,
  queryClient,
  children,
}: Props) => {
  return (
    <ApiContext.Provider value={onAuthFailure || defaultFunc}>
      <QueryClientProvider client={queryClient || new QueryClient()}>
        <Hydrate state={dehydratedState}>{children}</Hydrate>
      </QueryClientProvider>
    </ApiContext.Provider>
  );
};

export default APIContainer;
