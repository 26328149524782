import { useCallback } from "react";

import { useQueryClient } from "react-query";
import { setCookie, destroyCookie } from "nookies";

import { apiUrls as urls } from "config";
import { UserDetails } from "interfaces";
import { makeFetchRequest, useGetFetcher } from "api";

import { useUserDetails, useResetUserDetails } from "../auth-state";

const isHttp = () => {
  const url = urls?.login;
  if (!url) {
    return false;
  }
  return url.startsWith("http://");
};

export const useLoginSubmit = () => {
  const url = urls?.login;
  const profileURL = urls?.me;

  const fetcher = useGetFetcher<UserDetails>();

  const [, updateUserDetails] = useUserDetails();

  return useCallback(
    async (email: string, password: string) => {
      if (!url) {
        throw Error("Login URL not in context!!!");
      }
      try {
        // Identity Server sends a HttpOnly cookie.
        // The browser refuses to set this cookie on http.
        // We need some way of persisting the token - using the daisho-jwt cookie for this.
        if (isHttp()) {
          destroyCookie(null, "daisho-jwt");
        }
        const { token } = await makeFetchRequest({
          url,
          data: { email, password },
          method: "post",
        });
        if (isHttp()) {
          setCookie(null, "daisho-jwt", token, {
            maxAge: 30 * 24 * 60 * 60,
            path: "/",
          });
        }

        if (profileURL) {
          const userDetails = await fetcher({
            url: profileURL,
            method: "GET",
          });
          updateUserDetails(userDetails);
        }

        return { success: true };
      } catch (e) {
        return {
          success: false,
          message: e.detail || e.non_field_errors?.[0] || "Unable to Login.",
        };
      }
    },
    [url, profileURL, fetcher, updateUserDetails]
  );
};

export const useLogout = () => {
  const url = urls?.logout;
  const client = useQueryClient();
  const resetUserDetails = useResetUserDetails();

  return useCallback(async () => {
    if (!url) {
      throw Error("Logout URL not in context!!!");
    }
    await makeFetchRequest({ url, method: "POST" });
    client.clear();
    resetUserDetails();
  }, [url, client, resetUserDetails]);
};

interface PasswordChange {
  current_password: string;
  new_password: string;
}

export const useChangePassword = () => {
  const url = urls?.change_password;

  return useCallback(
    async (data: PasswordChange) => {
      if (!url) {
        throw Error("Change Password URL not in context!!!");
      }
      try {
        await makeFetchRequest({ url, data, method: "POST" });
        return true;
      } catch (error) {
        return false;
      }
    },
    [url]
  );
};
