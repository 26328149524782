import { useEffect } from "react";
import { useRouter } from "next/router";

import { UserDetails } from "interfaces";

import { useUpdateProfile } from "./api";
import { useGetLoginStatus } from "./auth-state";

export const useAuthProtect = () => {
  const router = useRouter();
  const loggedIn = useGetLoginStatus();

  const { status: profileStatus } = useUpdateProfile();

  const { asPath } = router;

  useEffect(() => {
    if (typeof window !== undefined) {
      // The first time when a user goes, logged will always be -ve.
      // And then there will be a race condition.
      // So delaying redirect till the fetch is resolved.
      if (
        !loggedIn &&
        (profileStatus === "success" || profileStatus === "error")
      ) {
        router.push(`/login?redirect=${encodeURI(decodeURI(asPath))}`);
      }
    }
  }, [loggedIn, asPath, profileStatus]);
};

export const checkForExpiry = (userDetails: UserDetails) => {
  if (!userDetails.is_active) {
    return { active: false, reason: "subscription" };
  }

  const { expiry_date, license_expiry_date } = userDetails;
  const timeToExpiry = new Date(expiry_date).valueOf() - Date.now().valueOf();

  if (timeToExpiry <= 0) {
    return {
      active: false,
      reason: "subscription",
      expiryDate: new Date(expiry_date).toDateString(),
    };
  }

  const timeToLicenseExpiry =
    new Date(license_expiry_date).valueOf() - Date.now().valueOf();
  if (timeToLicenseExpiry <= 0) {
    return {
      active: false,
      reason: "license",
      expiryDate: new Date(license_expiry_date).toDateString(),
    };
  }

  return { active: true };
};
