import { useCallback } from "react";
import { useQueryClient } from "react-query";

import { Project } from "interfaces";
import {
  useFetchEntity,
  useFetchMultipleEntities,
  useFetchPaginatedData,
  useShareEntity,
  useRevokeEntityAccessPermissions,
  useDeleteEntity,
  useGetFetcher,
} from "api";

import { apiUrls as urls } from "config";

export function useFetchProject<T extends Project = Project>(
  projectId: string | undefined
) {
  return useFetchEntity<T>(urls.projects, projectId);
}

export const useFetchProjects = (projectIds: string[]) => {
  return useFetchMultipleEntities<Project>(urls.projects, projectIds);
};

export const useFetchProjectListing = (projectType?: string) => {
  if (!projectType) {
    return useFetchPaginatedData<Project>(urls.projects);
  } else {
    return useFetchPaginatedData<Project>(
      `${urls.projects}?flow=${projectType}`
    );
  }
};

export const useResetProjectQuery = (projectId?: string) => {
  const client = useQueryClient();
  return useCallback(() => {
    if (projectId) {
      client.invalidateQueries([urls.projects, projectId]);
    } else {
      client.invalidateQueries(urls.projects);
    }
  }, [client, projectId]);
};

export const useUpdateProject = (projectId: string) => {
  const client = useQueryClient();
  const makeFetchRequest = useGetFetcher<Project>();

  const updateProject = useCallback(async () => {
    const url = `${urls.projects}/${projectId}`;

    const resp = await makeFetchRequest({
      url,
      method: "GET",
    });

    client.setQueryData([urls.projects, projectId], resp);
    client.setQueryData<{ pages: Project[][] }>(urls.projects, (entities) => {
      if (!entities) {
        return { pages: [] };
      }
      const { pages } = entities;
      return {
        ...entities,
        pages: (pages || []).map((l) =>
          l.map((d) => (d.id !== projectId ? d : resp))
        ),
      };
    });
  }, [client, makeFetchRequest, projectId]);

  return updateProject;
};

export function useGetUpdateName<T extends Project = Project>(
  projectId: string
) {
  const client = useQueryClient();
  const makeFetchRequest = useGetFetcher<T>();

  const submit = useCallback(
    async (name: string) => {
      const url = `${urls.projects}/${projectId}`;

      const resp = await makeFetchRequest({
        url,
        method: "PUT",
        data: { name },
      });
      const data = client.getQueryData<T>([urls.projects, projectId]);
      client.setQueryData<T>([urls.projects, projectId], { ...data, name });
      client.setQueryData<{ pages: Project[][] }>(urls.projects, (entities) => {
        if (!entities) {
          return { pages: [] };
        }
        const { pages } = entities;
        return {
          ...entities,
          pages: (pages || []).map((l) =>
            l.map((d) => (d.id !== projectId ? d : { ...d, name }))
          ),
        };
      });
      return resp;
    },
    [projectId]
  );

  return submit;
}

export const useGetShareProject = (projectId: string) => {
  const share = useShareEntity();

  return useCallback(
    (permissions: { can_view?: string[]; can_use?: string[] }) => {
      return share({
        baseUrl: urls.projects,
        entityId: projectId,
        permissions: permissions,
      });
    },
    [urls, share, projectId]
  );
};

export const useGetRevokeProjectPermissions = (projectId: string) => {
  const share = useRevokeEntityAccessPermissions();

  return useCallback(
    (permissions: { can_view?: string[]; can_use?: string[] }) => {
      return share({
        baseUrl: urls.projects,
        entityId: projectId,
        permissions: permissions,
      });
    },
    [urls, share, projectId]
  );
};

export const useGetDeleteProject = (projectId: string) => {
  const deleteData = useDeleteEntity();

  return useCallback(() => {
    return deleteData({
      baseUrl: urls.projects,
      entityId: projectId,
    });
  }, [urls, projectId, deleteData]);
};

export function useGetCreate<T>(url: string) {
  const fetcher = useGetFetcher<{ request_id: string }>();

  const createData = useCallback(
    async (spec: T) => {
      return await fetcher({ url, method: "POST", data: spec });
    },
    [url, fetcher]
  );

  return createData;
}
