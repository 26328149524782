import Link from "next/link";

import { UserDetails } from "interfaces";

import DropDown from "./Dropdown";
import HeaderLink from "./Header-Link";
import Notifications from "./Notifications";

interface Props {
  paths: { [key: string]: string };
  loggedIn: boolean;
  logout: () => any;
  allowSignup: boolean;
  userDetails?: UserDetails;
}

const BigScreenHeader = ({
  paths,
  loggedIn,
  userDetails,
  logout,
  allowSignup,
}: Props) => {
  // Big Screen Navigation. Hidden for sm and below.
  return (
    <nav className={`navbar-menu lg:flex lg:flex-grow w-full hidden`}>
      <ul className="lg:mr-auto lg:flex lg:flex-grow lg:justify-end lg:ml-8 lg:flex-wrap items-center">
        <DropDown title="Data Files">
          <HeaderLink
            to={paths.uploadData}
            label="Upload"
            subtitle="Upload Fresh Data"
          />
          <HeaderLink
            to={paths.dataFiles}
            label="Explore"
            subtitle="See insights from your current data"
          />
          <HeaderLink
            to={paths.createData}
            label="Transform"
            subtitle="Create New Data using Transforms"
          />
          {/* <HeaderLink
            to={paths.dataMacro}
            label="Your Macros"
            subtitle="See and Use your existing Data Macros"
          /> */}
        </DropDown>
        <DropDown title="Projects">
          <HeaderLink
            to={paths.recipes}
            label="Create"
            subtitle="Start a New Project"
          />
          <HeaderLink
            to={paths.projects}
            label="Explore"
            subtitle="See Your Existing Projects"
          />
          {/* <HeaderLink
            to={paths.modelMacros}
            label="Your Macros"
            subtitle="See and Use your existing Model Macros"
          /> */}
        </DropDown>
        <div className="relative mx-2">
          <Link href={paths.dataApps}>
            <a className="text-gray-100 font-medium">Your Apps</a>
          </Link>
        </div>
        {loggedIn && userDetails ? (
          <>
            <Notifications />
            <DropDown title={userDetails.email}>
              <HeaderLink to={paths.profile} label="Profile" />
              <div className="block lg:inline-block hover:bg-gray-300 w-full cursor-pointer">
                <p
                  className="mt-2 lg:mt-0 font-medium text-gray-900 lg:px-5 lg:pt-3 lg:flex lg:items-center transition duration-150 ease-in-out lg:py-3"
                  onClick={logout}
                >
                  Logout
                </p>
              </div>
            </DropDown>
          </>
        ) : (
          <>
            <div className="relative mr-2">
              <Link href={paths.login}>
                <a className="text-gray-100 font-medium">Sign In</a>
              </Link>
            </div>
            {allowSignup && (
              <div className="relative">
                <div className="btn-sm text-gray-200 bg-primary-main hover:bg-primary-dark rounded">
                  <Link href={paths.signup}>
                    <a className="block lg:inline-block w-full">
                      <p className="font-medium px-5 py-3 flex items-center transition duration-150 ease-in-out">
                        <span>Try for Free</span>
                        <span>
                          <svg
                            className="w-3 h-3 fill-current text-gray-200 flex-shrink-0 ml-2 -mr-1"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                              fillRule="nonzero"
                            />
                          </svg>
                        </span>
                      </p>
                    </a>
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </ul>
    </nav>
  );
};

export default BigScreenHeader;
