import clsx from "clsx";

interface Props {
  variant?: "error" | "success" | "info" | "warning";
  children: React.ReactNode;
  className?: string;
}

const Alert = ({ variant = "error", children, className = "" }: Props) => (
  <div
    className={clsx(
      variant === "error" && "bg-red-200 border border-red-700",
      variant === "success" && "bg-green-200 border border-green-700",
      variant === "warning" && "bg-yellow-300 border-yellow-700",
      variant === "info" && "bg-blue-200 border border-blue-700",
      variant && "text-gray-800",
      "flex items-center m-1 font-medium py-1 px-2 rounded-md",
      className
    )}
  >
    <div className="flex-grow-0 flex-shrink-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="w-5 h-5 mx-2"
      >
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="8" x2="12" y2="12" />
        <line x1="12" y1="16" x2="12.01" y2="16" />
      </svg>
    </div>
    <div className="font-normal pl-2 ml-2 my-1 max-w-full">{children}</div>
  </div>
);

export default Alert;
