import { useEffect, useMemo } from "react";

import { Project } from "interfaces";
import { useFetchModelListingForMultipleWorkspaces } from "modules/predictive-models/api";

export const useGetModelsByRunForProject = (
  project: Project,
  runIdKey: string,
  createdTimeKey?: string
) => {
  const workspaces = project.workspaces.map((w) => w.workspace);

  const [
    listing,
    status,
    hasNextPage,
    fetchNextPage,
  ] = useFetchModelListingForMultipleWorkspaces(workspaces);

  useEffect(() => {
    if (status !== "loading" && hasNextPage) {
      fetchNextPage();
    }
  }, [status, hasNextPage, fetchNextPage]);

  return useMemo(() => {
    let runIds = Array.from(
      new Set(listing.flat().map((m) => m.annotations?.[runIdKey]))
    );
    const modelsByRun = Object.fromEntries(
      runIds.map((r) => [
        r,
        listing.flat().filter((m) => m.annotations?.[runIdKey] === r),
      ])
    );

    runIds = runIds
      .filter((r) => (modelsByRun[r] || []).length >= 1)
      .sort((a, b) => {
        const aModel = modelsByRun[a][0];
        const bModel = modelsByRun[b][0];
        const aCreatedTime = new Date(
          aModel.annotations?.[createdTimeKey] || aModel.created_time
        ).valueOf();
        const bCreatedTime = new Date(
          bModel.annotations?.[createdTimeKey] || bModel.created_time
        ).valueOf();

        return bCreatedTime - aCreatedTime;
      });

    const runsInWorkspaces = Object.fromEntries(
      workspaces.map((ws) => {
        const r = Object.keys(modelsByRun).filter(
          (rId) =>
            modelsByRun[rId].filter((mm) => mm.workspace === ws).length >= 1
        );
        return [ws, r];
      })
    );

    return { runIds, modelsByRun, runsInWorkspaces };
  }, [listing, project]);
};
