import { useEffect, useState } from "react";
import { QueryStatus } from "react-query";
import { useFetchPaginatedData } from "./fetch-entities";

export function useFetchAllEntities<T = unknown>(
  url: string
): [T[], QueryStatus] {
  const [receivedEntities, updateReceivedModels] = useState<T[]>([]);

  const [
    entities,
    status,
    hasNextPage,
    fetchNextPage,
  ] = useFetchPaginatedData<T>(url);

  useEffect(() => {
    if (status !== "loading") {
      if (hasNextPage) {
        fetchNextPage();
      } else {
        const m = entities.flat();
        updateReceivedModels(m);
      }
    }
  }, [hasNextPage, fetchNextPage, entities, status, updateReceivedModels]);

  return [receivedEntities, status];
}
