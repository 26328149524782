import { useEffect } from "react";

import { Api } from "interfaces";

import Alert from "ui-components/Alert";

import { useSetTaskSeen } from "./store";

import AddWorkspaceMessage from "./Messages/Add-Workspace";
import BuildModelMessage from "./Messages/Build-Model";

interface Task extends Api.TaskStatus, Api.Task {}

const Message = ({ task }: { task: Task }) => {
  const { task_type, status } = task;

  switch (task_type) {
    case "projects/add-workspace":
      return <AddWorkspaceMessage task={task} />;
    case "build-model":
    case "model-iterate":
      return <BuildModelMessage task={task} />;
  }

  if (status === "SUCCESS") {
    return <p>Successfully completed task.</p>;
  } else if (status === "FAILURE") {
    return <p>Failed to complete task.</p>;
  } else {
    return <p>Task in Progress</p>;
  }
};

interface Props {
  task: Task;
  mode: "toast" | "dropdown";
}

const Wrapper = ({ task, mode }: Props) => {
  const t = task;
  // const setTaskSeen = useSetRecoilState(taskSeenAtom(task.job_id));
  const setTaskSeen = useSetTaskSeen(task.job_id);
  useEffect(() => {
    if (mode === "dropdown") {
      setTaskSeen(true);
    } else {
      setTimeout(() => setTaskSeen(true), 5000);
    }
  }, [mode, setTaskSeen]);
  return (
    <Alert
      variant={
        t.status === "FAILURE"
          ? "error"
          : t.status === "IN PROGRESS"
          ? "info"
          : t.result?.error
          ? "error"
          : "success"
      }
      className="px-1"
    >
      <p className="text-left" onClick={() => setTaskSeen(false)}>
        {new Date(`${t.time_stamp}`).toLocaleDateString()}{" "}
        {new Date(`${t.time_stamp} UTC`).toLocaleTimeString(undefined, {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </p>
      <Message task={t} />
    </Alert>
  );
};

export default Wrapper;
