import clsx from "clsx";

interface Props {
  ping?: boolean;
  count?: number;
}

const NotificationIcon = ({ ping, count }: Props) => {
  return (
    <>
      <button className="ml-auto flex-shrink-0 p-1 rounded-full text-gray-300 hover:text-gray-100 focus:ring-0">
        <span className="sr-only">View notifications</span>
        {/* <!-- Heroicon name: bell --> */}
        <span className="relative inline-block">
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            />
          </svg>

          <span
            className={clsx(
              "absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-2/3 -translate-y-1/2 bg-red-600 rounded-full",
              count === 0 && "hidden"
            )}
          >
            {count}
          </span>
        </span>
      </button>
      {ping && (
        <span className="flex h-3 w-3">
          <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-400 opacity-75"></span>
        </span>
      )}
    </>
  );
};

export default NotificationIcon;
