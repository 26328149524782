import { atom, useAtom } from "jotai";
import { useAtomValue, atomWithReset, useResetAtom } from "jotai/utils";

import { UserDetails } from "interfaces";

const loginInProgressAtom = atom(false);

export const useLoginProgress = () => useAtom(loginInProgressAtom);

const logoutInProgressAtom = atom(false);

export const useLogoutStatus = () => useAtom(logoutInProgressAtom);

export const useGetLogoutStatus = () => useAtomValue(logoutInProgressAtom);

const userDetails = atomWithReset<UserDetails>(null);

export const useUserDetails = () => useAtom(userDetails);

export const useGetUserDetails = () => useAtomValue(userDetails);

export const useResetUserDetails = () => useResetAtom(userDetails);

export const useGetLoginStatus = () => {
  const loggedIn = !!useAtomValue(userDetails);
  const loginInProgress = useAtomValue(logoutInProgressAtom);
  const logoutInProgress = useAtomValue(logoutInProgressAtom);
  return loggedIn && !logoutInProgress && !loginInProgress;
};

export const useGetLicenseValidity = () => {
  const userDetails = useGetUserDetails();
  if (!userDetails) {
    return true;
  }
  const now = new Date();
  const expiry = new Date(userDetails.expiry_date);

  return expiry > now;
};
