import { useCallback } from "react";
import { useQueryClient, QueryClient } from "react-query";

import { apiUrls as urls } from "config";

import { Workspaces, Api } from "interfaces";
import { useGetFetcher } from "api";

const updateClient = (
  client: QueryClient,
  modelId: string,
  annotationsToAdd?: { [key: string]: any },
  annotationsToRemove?: string[]
) => {
  const data = client.getQueryData<Workspaces.PredictiveModel>([
    urls.predictive_models,
    modelId,
  ]);
  const newAnnotations = Object.fromEntries(
    Object.entries({ ...data.annotations, ...(annotationsToAdd || {}) }).filter(
      ([k]) => !(annotationsToRemove || []).includes(k)
    )
  );
  const updatedData = { ...data, annotations: newAnnotations };
  client.setQueryData<Workspaces.PredictiveModel>(
    [urls.predictive_models, modelId],
    updatedData
  );
  client.setQueryData<{ pages: Workspaces.PredictiveModel[][] }>(
    urls.predictive_models,
    (entities) => {
      if (!entities) {
        return { pages: [] };
      }
      const { pages } = entities;
      return {
        ...entities,
        pages: (pages || []).map((l) =>
          l.map((d) => (d.id !== modelId ? d : updatedData))
        ),
      };
    }
  );
};

export const useUpdateModelAnnotations = (modelId?: string) => {
  const fetcher = useGetFetcher<Workspaces.PredictiveModel>();
  const client = useQueryClient();

  return useCallback(
    async (annotations: { [key: string]: any }, mId?: string) => {
      const id = mId || modelId;
      if (!id) {
        throw Error("Model ID not passed!!!");
      }
      if (Object.keys(annotations).length === 0) {
        return;
      }
      const url = `${urls.predictive_models}/${modelId}/update-annotations`;
      const resp = await fetcher({
        url,
        method: "POST",
        data: { annotations },
      });
      if ((resp as Api.ComputeStatus).error) {
        throw (resp as Api.ComputeStatus).error_message;
      }
      updateClient(client, id, annotations, []);
      return resp;
    },
    [modelId, fetcher]
  );
};

export const useRemoveModelAnnotations = (modelId?: string) => {
  const fetcher = useGetFetcher<Workspaces.PredictiveModel>();
  const client = useQueryClient();

  return useCallback(
    async (keys_to_be_removed: string[], mId?: string) => {
      const id = mId || modelId;
      if (!id) {
        throw Error("Model ID not passed!!!");
      }
      if ((keys_to_be_removed || []).length === 0) {
        return;
      }
      const url = `${urls.predictive_models}/${modelId}/update-annotations`;
      const resp = await fetcher({
        url,
        method: "POST",
        data: { keys_to_be_removed },
      });
      if ((resp as Api.ComputeStatus).error) {
        throw (resp as Api.ComputeStatus).error_message;
      }
      updateClient(client, id, {}, keys_to_be_removed);
      return resp;
    },
    [modelId, fetcher]
  );
};
