import { useQuery } from "react-query";
import { Api } from "interfaces";

import { apiUrls } from "config";
import { useGetFetcher, makeFetchRequest } from "api";

interface Task extends Api.TaskStatus, Api.Task {}

export const fetchTasks = async (
  completed?: true,
  inProgress?: true,
  failed?: true
) => {
  const data = {
    details: true,
    completed: completed,
    "in-progress": inProgress,
    failed: failed,
  };

  return await makeFetchRequest<Task[]>({ url: apiUrls.task_list, data });
};

export const useFetchTasks = (
  completed?: true,
  inProgress?: true,
  failed?: true
) => {
  const fetcher = useGetFetcher();

  const data = {
    details: true,
    completed: completed,
    "in-progress": inProgress,
    failed: failed,
  };

  return useQuery<Task[]>([apiUrls.task_list, data], () =>
    fetcher({ url: apiUrls.task_list, data })
  );
};

export const useFetchTaskDetails = (taskId: string) => {
  const fetcher = useGetFetcher();

  const url = `${apiUrls.task_list}/${taskId}`;

  return useQuery<Api.Task[]>(url, () => fetcher({ url: apiUrls.task_list }));
};
