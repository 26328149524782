import { useCallback, useEffect } from "react";
import {
  useQuery,
  QueryObserverResult,
  useQueryClient,
  useQueries,
} from "react-query";

import { apiUrls as urls } from "config";

import { Workspaces, DataFile, Api } from "interfaces";
import { useGetFetcher, useFetchPaginatedData, useFetchAllEntities } from "api";
import { useCompute } from "api";
import {
  useShareEntity,
  useRevokeEntityAccessPermissions,
  useDeleteEntity,
  useFetchEntity,
  useFetchMultipleEntities,
} from "api";

import { useGetDataUploadReducer } from "utils/file-upload-status";

type ApiError = Api.ApiError;

export const useFetchModelListing = () =>
  useFetchPaginatedData<Workspaces.PredictiveModel>(urls.predictive_models);

export const useFetchInputCreationChainForModel = (modelID: string) => {
  const fetcher = useGetFetcher();
  const url = `${urls.predictive_models}/${modelID}/${urls.input_creation_chain}`;
  return useQuery<DataFile.CreationChain, string>(url, () => fetcher({ url }));
};

export const useFetchModelListingForWorkspace = (workspaceId: string) =>
  useFetchPaginatedData<Workspaces.PredictiveModel>(
    `${urls.predictive_models}?workspace=${workspaceId}`
  );

export const useFetchModelListingForMultipleWorkspaces = (
  workspaceIds: string[]
) =>
  useFetchPaginatedData<Workspaces.PredictiveModel>(
    `${urls.predictive_models}?workspace=${workspaceIds.join(",")}`
  );

export const useFetchFullModelListingForWS = (workspaceId: string) =>
  useFetchAllEntities<Workspaces.PredictiveModel>(
    `${urls.predictive_models}?workspace=${workspaceId}`
  );

export const useFetchModel = (
  modelId: string | undefined
): [
  QueryObserverResult<Workspaces.PredictiveModel, Api.ApiError>,
  QueryObserverResult<Workspaces.WorkSpace, Api.ApiError>
] => {
  const modelResp = useFetchEntity<Workspaces.PredictiveModel>(
    urls.predictive_models,
    modelId
  );
  const workspaceResp = useFetchEntity<Workspaces.WorkSpace>(
    urls.workspaces,
    modelResp.data?.workspace
  );
  return [modelResp, workspaceResp];
};

export const useGetDisplayParamsForModel = (modelId: string | undefined) => {
  const { data: model } = useFetchEntity<Workspaces.PredictiveModel>(
    urls.predictive_models,
    modelId
  );
  const workspaceId = model?.workspace;
  const url = `${urls.workspaces}/${workspaceId}/display-params`;
  const fetcher = useGetFetcher<Workspaces.DisplayParams>();

  return useQuery<Workspaces.DisplayParams, Api.ApiError>(
    workspaceId ? url : "",
    () => fetcher({ url }),
    {
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!workspaceId,
    }
  );
};

export const useGetIterateParamsForModel = (modelId: string | undefined) => {
  const { data: model } = useFetchEntity<Workspaces.PredictiveModel>(
    urls.predictive_models,
    modelId
  );
  const workspaceId = model?.workspace;
  const url = `${urls.workspaces}/${workspaceId}/iterate-params`;
  const fetcher = useGetFetcher<Workspaces.IterateParams>();

  return useQuery<Workspaces.IterateParams, Api.ApiError>(
    workspaceId ? url : "",
    () => fetcher({ url }),
    {
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!workspaceId,
    }
  );
};

export const useFetchMultipleModels = (modelIds: string[]) =>
  useFetchMultipleEntities<Workspaces.PredictiveModel>(
    urls.predictive_models,
    modelIds
  );

export const useGetShareModel = (modelId: string) => {
  const shareEntity = useShareEntity();
  return useCallback(
    (permissions: { can_view?: string[]; can_use?: string[] }) => {
      return shareEntity({
        baseUrl: urls.workspaces,
        entityId: modelId,
        permissions: permissions,
      });
    },
    [urls, shareEntity, modelId]
  );
};

export const useGetRevokeModelAccessPermissions = (modelId: string) => {
  const revokePermissions = useRevokeEntityAccessPermissions();
  return useCallback(
    (permissions: { can_view?: string[]; can_use?: string[] }) => {
      return revokePermissions({
        baseUrl: urls.workspaces,
        entityId: modelId,
        permissions: permissions,
      });
    },
    [urls, revokePermissions, modelId]
  );
};

export const useDeleteModel = (modelId: string, workspaceId?: string) => {
  const baseUrl = workspaceId
    ? `${urls.predictive_models}?workspace=${workspaceId}`
    : urls.predictive_models;

  const deleteData = useDeleteEntity();
  return useCallback(() => {
    return deleteData({
      baseUrl: baseUrl,
      entityId: modelId,
    });
  }, [baseUrl, modelId, deleteData]);
};

export const useDeleteMultipleModels = (modelIds: string[]) => {
  const baseUrl = urls.predictive_models;

  const deleteData = useDeleteEntity();
  return useCallback(() => {
    modelIds.forEach((id) => deleteData({ baseUrl, entityId: id }));
  }, [baseUrl, modelIds, deleteData]);
};

export const useSetupPredict = (modelId: string) => {
  const url = `${urls.predictive_models}/${modelId}/enable-predictions`;
  return useCompute(url, false);
};

export const useSetupAutoLearn = (modelId: string) => {
  const url = `${urls.predictive_models}/${modelId}/enable-auto-learn`;
  return useCompute(url, false);
};

export const useGenerateExport = (modelId: string) => {
  const url = `${urls.predictive_models}/${modelId}/exports`;
  const fetcher = useGetFetcher();

  return useCallback(
    async (params: { [key: string]: any } = {}) => {
      return fetcher({ url, method: "POST", data: params });
    },
    [url, fetcher]
  );
};

export const useFetchPredictionParameters = (modelId: string | undefined) => {
  const url = `${urls.predictive_models}/${modelId}/feature-details-for-predictions`;
  const fetcher = useGetFetcher<Workspaces.ModelPredictionParameters>();
  return useQuery<Workspaces.ModelPredictionParameters, Api.ApiError>(
    url,
    () => fetcher({ url }),
    {
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!modelId,
    }
  );
};

export const useFetchPredictionParametersForMultipleModels = (
  modelIds: string[]
) => {
  const makeFetchRequest = useGetFetcher<Workspaces.ModelPredictionParameters>();
  return useQueries(
    modelIds.map((modelId) => ({
      queryKey: [
        `${urls.predictive_models}/${modelId}/feature-details-for-predictions`,
      ],
      queryFn: () =>
        makeFetchRequest({
          url: `${urls.predictive_models}/${modelId}/feature-details-for-predictions`,
        }),
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!modelId,
    }))
  ) as QueryObserverResult<Workspaces.ModelPredictionParameters, ApiError>[];
};

export function usePredictRow<T = Workspaces.PredictionResult>(
  modelId?: string,
  getConfidenceIntervals?: boolean
) {
  const fetcher = useGetFetcher<T>();

  getConfidenceIntervals = getConfidenceIntervals || false;

  return useCallback(
    async (
      data: { [column: string]: string | number | Date },
      mId?: string,
      getConfidence?: boolean
    ) => {
      const id = mId || modelId;
      if (!id) {
        throw Error("Model ID not passed!!!");
      }
      if (getConfidence === undefined || getConfidence === null) {
        getConfidence = getConfidenceIntervals;
      }
      const url = `${urls.predictive_models}/${id}/predict-row`;
      const resp = await fetcher({
        url,
        method: "POST",
        data: { data, get_confidence_levels: getConfidence },
      });
      if ((resp as Api.ComputeStatus).error) {
        throw (resp as Api.ComputeStatus).error_message;
      }
      return resp;
    },
    [modelId, fetcher, getConfidenceIntervals]
  );
}

export const usePredictFile = (modelId?: string) => {
  const fetcher = useGetFetcher();
  const [uploadStatus, dispatch] = useGetDataUploadReducer();

  const predictFile = useCallback(
    async (
      files: File[],
      mapping: { [key: string]: string[] },
      mId?: string
    ) => {
      const id = mId || modelId;
      console.log(id, files);
      if (!id) {
        throw Error("Model ID not passed!!!");
      }

      if (files.length === 0) {
        const err = new Error("No files selected!!!");
        throw err;
      }

      const url = `${urls.predictive_models}/${id}/predict-file`;
      dispatch({ type: "init", payload: { files } });
      const resp = await fetcher({
        url,
        method: "POST",
        data: { mapping, create_data_update: true },
        files: files,
        timeout: 1000 * 60 * 120,
        onUploadProgress: (progressEvent) => {
          dispatch({
            type: "update-progress",
            payload: { file: "files", progress: progressEvent * 100 },
          });
        },
      });

      return resp;
    },
    [modelId, fetcher]
  );

  useEffect(() => {
    return () => {
      dispatch({ type: "reset" });
    };
  }, [dispatch]);

  return { predictFile, uploadStatus: uploadStatus?.["files"] };
};

export const useUpdateModel = (modelId?: string) => {
  const url = `${urls.predictive_models}/${modelId}/update-model`;
  const compute = useCompute(url, false);

  return useCallback(
    async (
      files: File[],
      mapping: { [key: string]: string[] },
      mId?: string
    ) => {
      const id = mId || modelId;
      if (!id) {
        throw Error("Model ID not passed!!!");
      }

      const url = `${urls.predictive_models}/${id}/update-model`;
      return await compute({
        computeUrl: url,
        data: { mapping },
        files: files,
      });
    },
    [modelId, compute]
  );
};

interface TemplateSpec {
  template: string;
  source_data: { [key: string]: string };
  enable_auto_learn?: boolean;
  enable_predict?: boolean;
}

export const useCreateModelUsingMacro = () => {
  const url = urls.apply_model_macro;
  const fetcher = useGetFetcher<{ request_id: string }>();
  return useCallback(
    async (spec: TemplateSpec) =>
      await fetcher({ url, method: "POST", data: spec }),
    [url, fetcher]
  );
};

export const useGetUpdateModelName = (modelId: string) => {
  const client = useQueryClient();
  const makeFetchRequest = useGetFetcher<Workspaces.PredictiveModel>();

  const submit = useCallback(
    async (name: string) => {
      const url = `${urls.predictive_models}/${modelId}`;

      const resp = await makeFetchRequest({
        url,
        method: "PUT",
        data: { name },
      });
      const data = client.getQueryData<Workspaces.PredictiveModel>([
        urls.predictive_models,
        modelId,
      ]);
      client.setQueryData<Workspaces.PredictiveModel>(
        [urls.predictive_models, modelId],
        {
          ...data,
          name,
        }
      );
      client.setQueryData<{ pages: Workspaces.PredictiveModel[][] }>(
        urls.predictive_models,
        (entities) => {
          if (!entities) {
            return { pages: [] };
          }
          const { pages } = entities;
          return {
            ...entities,
            pages: (pages || []).map((l) =>
              l.map((d) => (d.id !== modelId ? d : { ...d, name }))
            ),
          };
        }
      );
      return resp;
    },
    [modelId]
  );

  return submit;
};

export const useUpdateModelMetaData = (modelId: string) => {
  const client = useQueryClient();
  const makeFetchRequest = useGetFetcher();

  const submit = useCallback(
    async (metaData: { [key: string]: any }, overwrite?: false) => {
      const url = `${urls.predictive_models}/${modelId}/update-metadata`;
      const resp = await makeFetchRequest({
        url,
        method: "PUT",
        data: { meta_data: metaData, overwrite_existing: overwrite || false },
      });
      const data = client.getQueryData<Workspaces.PredictiveModel>([
        urls.predictive_models,
        modelId,
      ]);
      client.setQueryData<Workspaces.PredictiveModel>(
        [urls.predictive_models, modelId],
        {
          ...data,
          model_meta_data: overwrite
            ? metaData
            : { ...(data.model_meta_data || {}), ...metaData },
        }
      );
      client.setQueryData<{ pages: Workspaces.PredictiveModel[][] }>(
        urls.predictive_models,
        (entities) => {
          if (!entities) {
            return { pages: [] };
          }
          const { pages } = entities;
          return {
            ...entities,
            pages: (pages || []).map((l) =>
              l.map((d) =>
                d.id !== modelId
                  ? d
                  : {
                      ...d,
                      model_meta_data: overwrite
                        ? metaData
                        : { ...(d.model_meta_data || {}), ...metaData },
                    }
              )
            ),
          };
        }
      );
      return resp;
    },
    [modelId]
  );

  return submit;
};
