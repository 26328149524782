import { useMutation, useQueryClient } from "react-query";

import { Api } from "interfaces";
import { useGetFetcher } from "../fetch";

type ApiError = Api.ApiError;

interface Permissions {
  can_view?: string[];
  can_use?: string[];
  can_change_permissions?: string[];
  can_delete?: string[];
}

interface Entity {
  id: string;
  permissions?: Permissions;
}

interface DeleteProps {
  baseUrl: string;
  entityId: string;
}

export function useDeleteEntity<T extends Entity>(): (
  params: DeleteProps
) => any {
  const client = useQueryClient();
  const makeFetchRequest = useGetFetcher<T>();
  const { mutate } = useMutation<T, ApiError, DeleteProps>(
    async ({ baseUrl, entityId }) => {
      setTimeout(() => {}, 5000);
      const resp = await makeFetchRequest({
        url: `${baseUrl}/${entityId}`,
        method: "DELETE",
      });
      return resp;
    },
    {
      onSuccess: (_, { baseUrl, entityId }) => {
        client.setQueryData<{ pages: T[][] }>(baseUrl, (entities) => {
          if (!entities) {
            return { pages: [] };
          }
          const { pages } = entities;
          return {
            ...entities,
            pages: (pages || []).map((l) => l.filter((d) => d.id !== entityId)),
          };
        });
        client.removeQueries([baseUrl, entityId]);
      },
    }
  );

  return mutate;
}

interface AccessPermissionsProps {
  baseUrl: string;
  entityId: string;
  permissions: { can_view?: string[]; can_use?: string[] };
}

function addPermissions<T extends Entity>(
  data: T,
  permissions: Permissions
): T {
  let newPermissions = { ...(data.permissions || {}) };
  newPermissions.can_view = Array.from(
    new Set([
      ...(newPermissions.can_view || []),
      ...(permissions.can_view || []),
    ])
  );
  newPermissions.can_use = Array.from(
    new Set([
      ...(newPermissions.can_use || []),
      ...(newPermissions.can_view || []),
    ])
  );
  return { ...data, permissions: newPermissions };
}

function removePermissions<T extends Entity>(
  data: T,
  permissions: Permissions
): T {
  let newPermissions = {
    ...(data.permissions || {}),
  };
  if (permissions.can_view) {
    newPermissions.can_view = newPermissions.can_view?.filter(
      (u) => !permissions.can_view.includes(u)
    );
  }
  if (permissions.can_use) {
    newPermissions.can_use = newPermissions.can_use?.filter(
      (u) => !permissions.can_use.includes(u)
    );
  }
  return { ...data, permissions: newPermissions };
}

export function useShareEntity<T extends Entity>() {
  const client = useQueryClient();
  const makeFetchRequest = useGetFetcher<T>();
  const { mutate } = useMutation<T, ApiError, AccessPermissionsProps>(
    async ({ baseUrl, entityId, permissions }) => {
      const url = `${baseUrl}/${entityId}/change-access-permissions`;
      const resp = await makeFetchRequest({
        url,
        method: "POST",
        data: permissions,
      });
      return resp;
    },
    {
      onMutate: ({ baseUrl, entityId, permissions }) => {
        const data = client.getQueryData<T>([baseUrl, entityId]);
        if (data) {
          client.setQueryData<T>(
            [baseUrl, entityId],
            addPermissions(data, permissions)
          );
        }
        client.setQueryData<{ pages: T[][] }>(baseUrl, (entities) => {
          if (!entities) {
            return { pages: [] };
          }
          const { pages } = entities;
          return {
            ...entities,
            pages: (pages || []).map((l) =>
              l.map((d) =>
                d.id !== entityId ? d : addPermissions(d, permissions)
              )
            ),
          };
        });
      },
      onError: (err, { baseUrl, entityId, permissions }) => {
        // Rollback. Removing the permissions
        const data = client.getQueryData<T>([baseUrl, entityId]);
        if (data) {
          client.setQueryData<T>(
            [baseUrl, entityId],
            removePermissions(data, permissions)
          );
        }
        client.setQueryData<{ pages: T[][] }>(baseUrl, (entities) => {
          if (!entities) {
            return { pages: [] } as { pages: T[][] };
          }
          const { pages } = entities;
          return {
            ...entities,
            pages: (pages || []).map((l) =>
              l.map((d) =>
                d.id !== entityId ? d : removePermissions(d, permissions)
              )
            ),
          };
        });
      },
    }
  );
  return mutate;
}

export function useRevokeEntityAccessPermissions<T extends Entity>() {
  const client = useQueryClient();
  const makeFetchRequest = useGetFetcher<T>();
  const { mutate } = useMutation<T, ApiError, AccessPermissionsProps>(
    async ({ baseUrl, entityId, permissions }) => {
      const url = `${baseUrl}/${entityId}/revoke-access-permissions`;
      const resp = await makeFetchRequest({
        url,
        method: "POST",
        data: permissions,
      });
      return resp;
    },
    {
      onSuccess: (_, { baseUrl, entityId, permissions }) => {
        const data = client.getQueryData<T>([baseUrl, entityId]);
        if (data) {
          client.setQueryData<T>(
            [baseUrl, entityId],
            removePermissions(data, permissions)
          );
        }

        client.setQueryData<{ pages: T[][] }>(baseUrl, (entities) => {
          if (!entities) {
            return { pages: [] };
          }
          const { pages } = entities;
          return {
            ...entities,
            pages: (pages || []).map((l) =>
              l.map((d) =>
                d.id !== entityId ? d : removePermissions(d, permissions)
              )
            ),
          };
        });
      },
      onError: (err, { baseUrl, entityId, permissions }) => {
        // Rollback. Adding back the permissions
        const data = client.getQueryData<T>([baseUrl, entityId]);
        if (data) {
          client.setQueryData<T>(
            [baseUrl, entityId],
            addPermissions(data, permissions)
          );
        }
        client.setQueryData<{ pages: T[][] }>(baseUrl, (entities) => {
          if (!entities) {
            return { pages: [] } as { pages: T[][] };
          }
          const { pages } = entities;
          return {
            ...entities,
            pages: (pages || []).map((l) =>
              l.map((d) =>
                d.id !== entityId ? d : addPermissions(d, permissions)
              )
            ),
          };
        });
      },
    }
  );

  return mutate;
}
